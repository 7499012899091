import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MHDialog from '../Common/Dialog/MHDialog';
import MHFormControl from '../Common/Form/MHFormControl';
import MHButton from '../Common/Button/MHButton';
import useInput from '../../hooks/use-input';

import * as validators from '../../utils/validators';
import * as constants from '../../utils/constants';
import { MHSelect } from '../Common/Form/MHSelect';
import { CONTACT_MODE } from '../../utils/landing-data';
import MHPhoneInput from '../Common/PhoneInput/MHPhoneInput';
import usePhoneInput from '../../hooks/use-phone';

///firebase
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { resolveErrorMessage } from '../../utils/utils';
import useForm from '../../hooks/use-form';
import { MuiTelInputInfo } from 'mui-tel-input';
import useDialog from '../../hooks/use-dialog';
import MHFormGroup from '../Common/Form/MHFormGroup';
import RequestConfirmationPrompt from '../Contact/RequestConfirmation';
import {
  Best_Description,
  Group_Leaders,
  Topics_Areas
} from '../../utils/summit-data';

const LeadersDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: enteredFirstNameHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);
  const {
    value: enteredBestDescription,
    valid: enteredBestDescriptionIsValid,
    error: enteredBestDescriptionHasError,
    onChange: bestDescriptionInputChangeHandler,
    onBlur: bestDescriptionInputBlurHandler
  } = useInput([{ validator: (value: string) => validators.required(value) }]);
  const {
    value: enteredGroupLeaders,
    valid: enteredGroupLeadersIsValid,
    error: enteredGroupLeadersHasError,
    onChange: groupLeadersInputChangeHandler,
    onBlur: groupLeadersInputBlurHandler
  } = useInput([{ validator: (value: string) => validators.required(value) }]);
  const {
    value: enteredTopicAreas,
    valid: enteredTopicAreasIsValid,
    error: enteredTopicAreasHasError,
    onChange: topicAreasInputChangeHandler,
    onBlur: topicAreasInputBlurHandler
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    error: enteredLastNameHasError,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredPosition,
    valid: enteredPositionIsValid,
    error: enteredPositionHasError,
    onChange: positionInputChangeHandler,
    onBlur: positionInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredCompany,
    valid: enteredCompanyIsValid,
    error: enteredCompanyHasError,
    onChange: companyInputChangeHandler,
    onBlur: companyInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredChallenges,
    valid: enteredChallengesIsValid,
    error: enteredChallengesHasError,
    onChange: challengesInputChangeHandler,
    onBlur: challengesInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);
  const {
    value: enteredExperience,
    valid: enteredExperienceIsValid,
    error: enteredExperienceHasError,
    onChange: experienceInputChangeHandler,
    onBlur: experienceInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const [completed, setCompleted] = React.useState(false);

  let formIsValid =
    enteredFirstNameIsValid &&
    enteredWorkEmailIsValid &&
    enteredLastNameIsValid &&
    enteredPositionIsValid &&
    enteredChallengesIsValid &&
    enteredTopicAreasIsValid &&
    enteredBestDescriptionIsValid &&
    enteredGroupLeadersIsValid &&
    enteredCompanyIsValid &&
    enteredExperienceIsValid;

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'REQUEST':
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null
        };
        return updatedRequestState;

      case 'RESPONSE':
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null
  });

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateForm();

    if (!formIsValid) {
      return;
    }

    dispatch({ type: 'REQUEST' });

    await addDoc(collection(db, 'leaders-inquiry-requests'), {
      FirstName: enteredFirstName,
      LastName: enteredLastName,
      WorkEmail: enteredWorkEmail,
      Experience: enteredExperience,
      Position: enteredPosition,
      Challenges: enteredChallenges,
      Description: enteredBestDescription,
      TopicAreas: enteredTopicAreas,
      Company: enteredCompany,
      createdAt: serverTimestamp()
    });

    handleOpenDialog();
    onClose();
    resetForm();

    dispatch({ type: 'RESPONSE', error: null, data: 'success' });
  };

  const { validateForm } = useForm();

  const resetForm = () => {
    firstNameInputChangeHandler('');
    workEmailInputChangeHandler('');
    lastNameInputChangeHandler('');
    challengesInputChangeHandler('');
    bestDescriptionInputChangeHandler('');
    companyInputChangeHandler('');
    groupLeadersInputChangeHandler('');
    topicAreasInputChangeHandler('');
    positionInputChangeHandler('');
    experienceInputChangeHandler('');
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  // let history = useHistory();

  // const handleClickOpen = () => {
  //   // history.push(`/`);
  //   // onClose();
  //   setCompleted(true);
  // };
  // const closure = () => {
  //   // history.push(`/`);
  //   resetForm();
  //   onClose();
  //   setCompleted(false);
  // };

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={!completed ? ' ' : ' '}
        handleClose={onClose}
        scroll="paper"
        actions={null}
        maxWidth={openDialog ? 'xs' : 'sm'}
        fullWidth>
        <MHFormGroup
          onSubmit={submitHandler}
          disableWhileSubmitting={httpState.loading}>
          <Typography
            variant="h2"
            fontSize={{ xs: '24px', sm: '28px', md: '30px' }}
            lineHeight={{ xs: '142%', sm: '143.5%' }}
            letterSpacing={'-0.04em'}
            className="capitalize mx-auto text-center"
            justifyContent="center"
            alignItems="center"
            textAlign={'center'}
            paddingBottom={4}>
            Workplace Leaders Inquiry
          </Typography>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="firstname"
                type="text"
                label="First Name"
                placeholder="What is your first name?"
                value={enteredFirstName}
                onChange={firstNameInputChangeHandler}
                onBlur={firstNameInputBlurHandler}
                error={resolveErrorMessage(enteredFirstNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
                required
                // error={merchantNameErrorTip}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="lastName"
                type="text"
                label="Last Name"
                placeholder="What is your last name?"
                value={enteredLastName}
                onChange={lastNameInputChangeHandler}
                onBlur={lastNameInputBlurHandler}
                required
                error={resolveErrorMessage(enteredLastNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="email"
                type="text"
                label=" Email"
                placeholder="Enter your email address"
                value={enteredWorkEmail}
                onChange={workEmailInputChangeHandler}
                onBlur={workEmailInputBlurHandler}
                required
                error={resolveErrorMessage(enteredWorkEmailHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="position"
                type="text"
                label="Job Title"
                placeholder="What is your role?"
                value={enteredPosition}
                onChange={positionInputChangeHandler}
                onBlur={positionInputBlurHandler}
                required
                error={resolveErrorMessage(enteredPositionHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="company"
                type="text"
                label="Company"
                placeholder="What is the name of the company you work for?"
                value={enteredCompany}
                onChange={companyInputChangeHandler}
                onBlur={companyInputBlurHandler}
                required
                error={resolveErrorMessage(enteredCompanyHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHSelect
                label="Which of the following best describes you?"
                placeholder="Which of the following best..."
                options={Best_Description}
                value={enteredBestDescription}
                onChange={(val) =>
                  bestDescriptionInputChangeHandler(val as string)
                }
                onBlur={bestDescriptionInputBlurHandler}
                error={resolveErrorMessage(enteredBestDescriptionHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={12}>
              <MHSelect
                label="If you are planning to attend  with a group, share how many people will be joining you                "
                placeholder="How many people will be joining you?                "
                options={Group_Leaders}
                value={enteredGroupLeaders}
                onChange={(val) =>
                  groupLeadersInputChangeHandler(val as string)
                }
                onBlur={groupLeadersInputBlurHandler}
                error={resolveErrorMessage(enteredGroupLeadersHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MHSelect
                label="Please indicate which of these topics are of interest to you and your organization                "
                placeholder="Which of these topics are of interest to you and your organization?"
                options={Topics_Areas}
                value={enteredTopicAreas}
                onChange={(val) => topicAreasInputChangeHandler(val as string)}
                onBlur={topicAreasInputBlurHandler}
                error={resolveErrorMessage(enteredTopicAreasHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={12}>
              <MHFormControl
                id="challenges"
                type="text"
                label="What challenges do you face in promoting flexibility, family leave, and inclusive... "
                placeholder="As a workplace leader, what specific challenges do you face in promoting flexibility, family leave, and inclusive leadership within your organization?                "
                value={enteredChallenges}
                onChange={challengesInputChangeHandler}
                onBlur={challengesInputBlurHandler}
                required
                multiline
                maxRows={4}
                error={resolveErrorMessage(enteredChallengesHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={12}>
              <MHFormControl
                id="experience"
                type="text"
                label="In your experience, what strategies or initiatives have been successful in fostering ...               "
                placeholder="In your experience, what strategies or initiatives have been successful in fostering a supportive and inclusive work culture?                "
                value={enteredExperience}
                onChange={experienceInputChangeHandler}
                onBlur={experienceInputBlurHandler}
                required
                multiline
                maxRows={4}
                error={resolveErrorMessage(enteredExperienceHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <MHButton
            fullWidth
            sx={{ paddingY: 2, marginY: 2 }}
            type="submit"
            // onClick={submitHandler}
            // onClick={() => handleOpenDialog()}
            loading={httpState.loading}
            className=" text-[12px] leading-[102%] w-full tracking-[0.05em] h-[52px] ">
            Submit
          </MHButton>
        </MHFormGroup>
      </MHDialog>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={handleCloseDialog}
          header="We'll be in touch soon!"
          message="Thank you for your interest in MH WorkLife. We will be in touch with
          you shortly."
        />
      )}
    </React.Fragment>
  );
};

export default LeadersDialog;
