import { SelectOption } from '@mui/base';
import { SxProps, Theme } from '@mui/material/styles';

export const DRAWER_WIDTH: number = 300;

export const DEFAULT_NOTIFICATION_DURATION: number = 4000;

export const EMAIL_FROM = 'noreply@mhworklife.com';

export const SUPPORT_EMAIL = 'hello@mhworklife.com';

export const DISCOUNT_CODE = 'AMN-MH-2023';

export const REQUIRED_ERROR_TIP = 'This field is required';

export const BACKGROUND_IMAGE_SX: SxProps<Theme> = {
  backgroundRepeat: 'no-repeat',
  // backgroundColor: 'background.default',
  backgroundPosition: 'center',
  backgroundSize: 'cover'
};

export const CONTACT_PURPOSE_LIST: Array<SelectOption<string>> = [
  {
    label: 'Care Fund/Wallet',
    value: 'Care Fund/Wallet'
  },
  {
    label: 'Care Academy',
    value: 'Care Academy'
  },
  {
    label: 'Care Coverage',
    value: 'Care Coverage'
  },
  {
    label: 'MH Plans - Individual / ERG / Enterprise',
    value: 'MH Plans - Individual / ERG / Enterprise'
  },
  {
    label: 'Brands and Partnerships',
    value: 'Brands and Partnerships'
  }
];

export const ROLE_TYPES: Array<SelectOption<string>> = [
  {
    label: 'Full Time',
    value: 'Full Time'
  },
  {
    label: 'Part Time',
    value: 'Part Time'
  }
];

export const JOB_LOCATIONS: Array<SelectOption<string>> = [
  {
    label: 'In-person',
    value: 'In-person'
  },
  {
    label: 'Remote',
    value: 'Remote'
  },
  {
    label: 'Hybrid',
    value: 'Hybrid'
  }
];

export const CALENDAR_TYPES: Array<SelectOption<string>> = [
  {
    label: 'Google Calendar',
    value: 'GOOGLE'
  },
  {
    label: 'Outlook',
    value: 'OUTLOOK'
  },
  {
    label: 'Apple Calendar',
    value: 'APPLE'
  }
];

export const RELATIONSHIP_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: 'single',
    label: 'Single'
  },
  {
    value: 'married',
    label: 'Married'
  },
  {
    value: 'committed',
    label: 'Committed'
  }
];

export const QUANTITY_OPTIONS: SelectOption<string>[] = [
  {
    value: '0',
    label: '0'
  },
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '4',
    label: '4'
  },
  {
    value: '5+',
    label: '5+'
  }
];

export const BOOL_OPTIONS: SelectOption<string>[] = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
];

export const IDENTITY_OPTIONS: SelectOption<string>[] = [
  {
    value: 'she/her',
    label: 'She/Her'
  },
  {
    value: 'he/him',
    label: 'He/Him'
  },
  {
    value: 'they/them',
    label: 'They/Them'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const RACE_OPTIONS: SelectOption<string>[] = [
  {
    value: 'Native American',
    label: 'Native American'
  },
  {
    value: 'Asian',
    label: 'Asian'
  },
  {
    value: 'Black or African American',
    label: 'Black or African American'
  },
  {
    value: 'Native Hawaiian or Other Pacific Islander',
    label: 'Native Hawaiian or Other Pacific Islander'
  },
  {
    value: 'Hispanic, Latino, or Spanish origin',
    label: 'Hispanic, Latino, or Spanish origin'
  },
  {
    value: 'White',
    label: 'White'
  },
  {
    value: 'Unknown',
    label: 'Unknown'
  },
  {
    value: 'Other/Prefer to self-describe',
    label: 'Other/Prefer to self-describe'
  },
  {
    value: "Don't wish to answer",
    label: "Don't wish to answer"
  }
];

export const ETHNICITY_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Asian or Pacific Islander',
    value: 'Asian or Pacific Islander'
  },
  {
    label: 'Black or African American',
    value: 'Black or African American'
  },
  {
    value: 'Hispanic or Latino',
    label: 'Hispanic or Latino'
  },
  {
    label: 'Native American or American Indian',
    value: 'Native American or American Indian'
  },
  {
    label: 'White',
    value: 'White'
  },
  {
    label: 'Decline Sharing',
    value: 'Decline Sharing'
  }
];

export const CARE_RESPONSIBILITY_OPTIONS: SelectOption<string>[] = [
  {
    value: 'self',
    label: 'Self'
  },
  {
    value: 'child',
    label: 'Child'
  },
  {
    value: 'parent',
    label: 'Parent'
  },
  {
    value: 'extended_family',
    label: 'Extended family'
  },
  {
    value: 'family_of_choice',
    label: 'Family of choice'
  },
  {
    value: 'household',
    label: 'Household'
  },
  {
    value: 'pet',
    label: 'Pet'
  }
];

export const CALENDAR_TYPES_OPTIONS: Array<SelectOption<string>> = [
  {
    label: 'Google Calendar',
    value: 'GOOGLE'
  },
  {
    label: 'Outlook',
    value: 'OUTLOOK'
  },
  {
    label: 'Apple Calendar',
    value: 'APPLE'
  }
];

export const COMPANY_SIZE_OPTIONS: Array<SelectOption<string>> = [
  {
    label: '1 - 100',
    value: '1 - 100'
  },
  {
    label: '101 - 500',
    value: '101 - 500'
  },
  {
    label: '501 - 1000',
    value: '501 - 1000'
  },
  {
    label: '1,000 - 2,000',
    value: '1,000 - 2,000'
  },
  {
    label: '2,000 - 5,000',
    value: '2,000 - 5,000'
  },
  {
    label: '5,000 - 10,000',
    value: '5,000 - 10,000'
  },
  {
    label: '10,000 - 25,000',
    value: '10,000 - 25,000'
  },
  {
    label: '25,000 - 75,000',
    value: '25,000 - 75,000'
  },
  {
    label: '75,000+',
    value: '75,000+'
  }
];

export const INFO_SOURCE_OPTIONS: Array<SelectOption<string>> = [
  {
    label: 'Social Media',
    value: 'Social Media'
  },
  {
    label: 'Google Search',
    value: 'Google Search'
  },
  {
    label: 'TechCrunch.com',
    value: 'TechCrunch.com'
  },
  {
    label: 'TechCrunch Disrupt',
    value: 'TechCrunch Disrupt'
  },
  {
    label: 'Instagram',
    value: 'Instagram'
  },
  {
    label: 'LinkedIn',
    value: 'LinkedIn'
  }
];

const FILE_TYPES: Array<string> = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon'
];

export const LOCATION_OPTIONS = [
  {
    id: 1,
    label: 'In-Person'
  },
  {
    id: 2,
    label: 'Digital Only'
  }
];

export const GAP_COVERAGE_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Parental Leave',
    value: 'Parental Leave'
  },
  {
    label: 'Sabbatical',
    value: 'Sabbatical'
  },
  {
    label: 'Sick Leave',
    value: 'Sick Leave'
  },
  {
    label: 'Extended Leave',
    value: 'Extended Leave'
  },
  {
    label: 'Bereavement Leave',
    value: 'Bereavement Leave'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const ROLE_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    label: 'Remote (U.S)',
    value: 'Remote (U.S)'
  },
  {
    label: 'Remote (anywhere)',
    value: 'Remote (anywhere)'
  },
  {
    label: 'In-Person (select)',
    value: 'In-Person (select)'
  },
  {
    label: 'Hybrid',
    value: 'Hybrid'
  }
];

export const INTEREST_TOPICS: SelectOption<string>[] = [
  {
    label: 'Employer-Sponsored Childcare',
    value: 'Employer-Sponsored Childcare'
  },
  {
    label: 'Eldercare Solutions',
    value: 'Eldercare Solutions'
  },
  {
    label: 'Equal Partnership At Home',
    value: 'Equal Partnership At Home'
  },
  {
    label: 'Stress, Burnout, Mental Health',
    value: 'Stress, Burnout, Mental Health'
  },
  {
    label: 'Paid Family Leave',
    value: 'Paid Family Leave'
  },
  {
    label: 'Flexible Work Arrangement',
    value: 'Flexible Work Arrangement'
  },
  {
    label: 'Famtech Solutions',
    value: 'Famtech Solutions'
  }
];

export const MANAGER_INTEREST_TOPICS: SelectOption<string>[] = [
  {
    label: 'Care & Wellbeing Programs',
    value: 'Care & Wellbeing Programs'
  },
  {
    label: 'Flexibility & Distributed Work',
    value: 'Flexibility & Distributed Work'
  },
  {
    label: 'Diversity, Equity & Inclusion',
    value: 'Diversity, Equity & Inclusion'
  },
  {
    label: 'Caring Leadership & Workplaces',
    value: 'Caring Leadership & Workplaces'
  },
  {
    label: 'Managing Parental Leave',
    value: 'Managing Parental Leave'
  },
  {
    label: 'Childcare & Eldercare  Support',
    value: 'Childcare & Eldercare  Support'
  },
  {
    label: 'Caregiver Retention Strategies',
    value: 'Caregiver Retention Strategies'
  },
  {
    label: 'WorkLife Solutions',
    value: 'WorkLife Solutions'
  }
];

export const GENDER: SelectOption<string>[] = [
  {
    label: 'Male',
    value: 'Male'
  },
  {
    label: 'Female',
    value: 'Female'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const OVERLAPPING_HOURS_OPTIONS: SelectOption<string>[] = [
  {
    label: '0 hours',
    value: '0 hours'
  },
  {
    label: '2 hours',
    value: '2 hours'
  },
  {
    label: '4 hours',
    value: '4 hours'
  },
  {
    label: '8 hours',
    value: '8 hours'
  },
  {
    label: '10 hours',
    value: '10 hours'
  },
  {
    label: '6 hours',
    value: '6 hours'
  }
];

export const ONGOING_SESSION_OPTIONS: SelectOption<string>[] = [
  {
    value: 'One-Time session',
    label: 'One-Time session'
  },
  {
    value: 'Series of sessions',
    label: 'Series of sessions'
  }
];
