

  export const FAQ_MEMBERSHIP_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 6,
      question: "What is the MH Employer Dashboard?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    
];

export const FAQ_SummitEvent_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 6,
      question: "What is the MH Employer Dashboard?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    
];

export const FAQ_Content_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 6,
      question: "What is the MH Employer Dashboard?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 7,
      question: "What is the MH Employer Wallet?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    
];

export const FAQ_Employers_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
   
    
];

export const FAQ_Consultants_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
  
    
];



export const FAQ_HeaderData =  [
  {
    title: "Memberships",
    linkTo: "membership",
    count: FAQ_MEMBERSHIP_Data.length,
  },
  {
    title: "Content",
    linkTo: "content",
    count: FAQ_Content_Data.length,
  },
  {
    title: "Summit & Events",
    linkTo: "summitandevent",
    count: FAQ_SummitEvent_Data.length,
  },
  {
    title: "Employers",
    linkTo: "employers",
    count: FAQ_Employers_Data.length,
  },
  {
    title: "Consultants",
    linkTo: "consultants",
    count: FAQ_Consultants_Data.length,
  }
];


export const Summit_FAQ_Data =  [
  {
    id: 1,
    question: "What is Care At Work Summit?",
    answer: "The Care At Work Summit is a one day interactive gathering where you come for sustainable solutions to care and work challenges, meet other women, caregivers, brands, employers, and business leaders from various organizations", 
  },
  {
    id: 2,
    question: "Where is the agenda?",
    answer: "Care At Work Summit agenda is being finalized! We can't wait to share the great line-up of conversations, activations, and speakers with you. Expect the nation’s top leaders in parenting, caregiving, work, and life.", 
  },
  {
    id: 3,
    question: "Can My ERG, HR, or DEI members attend?",
    answer: "Yes, this Summit exists at the intersection of career and life. We will be discussion how to approach our lives in caregiving, work, and life.", 
  },
  {
    id: 4,
    question: "Where Should I Stay?",
    answer: "Book surrounding hotels, Airbnb's, or other short-term rentals around Brooklyn NY. We will be sharing a list of our favourite hotels soon.", 
  },
  {
    id: 5,
    question: "What are the event's COVID-19 safety precautions?",
    answer: "With the return to the live for Care At Work Summit, MH WorkLife is dedicated to ensuring a great experience and keeping you safe during any ongoing pandemic conditions. The event will continue to adhere to the CDC and local health agencies recommendations, and will update event health policies as necessary.", 
  },
  {
    id: 6,
    question: "Why should I attend?",
    answer: "Be inspired to raise the bar in your own daily work, personal life, and at your own workplace through practical lessons from leaders who know what it takes to create, build, and sustain a place where everyone, no matter their background flourishes. Attend because you will learn from the nation’s experts, leaders, home front and work front catalyst across the country. You’ll listen first-hand to how these leaders and organizations solve for and champion care at home and in the workplace. Also come for the community, networking, and opportunities for your personal and professional goals.", 
  },
  {
    id: 7,
    question: "Can I buy event tickets when I get to the Summit?",
    answer: "This event has a limited quantity of tickets available and they are offered on a first come, first served basis. For the best chances of getting a spot at all the events you’d like to attend, register online in advance to secure your place!", 
  },
  {
    id: 8,
    question: "Will you accept additional vendors, partners, and sponsors?",
    answer: "Yes. Connect with our network of vetted women, parents, and caregivers. We are incredibly grateful to our current partners, and will appreciate your business at this time. Please email hello@mhworklife.com for our sales prospectus.", 
  },
  {
    id: 9,
    question: "What is the dress code?",
    answer: "You'll find a range of attire at the Summit but generally most attendees are in business casual. Temperatures in meeting places may fluctuate widely, we do recommend bringing a sweater or light jacket with you since personal preferences vary.", 
  },
  {
    id: 10,
    question: "Can I bring a guest to the Summit and networking events?",
    answer: "All attendees at the Summit and networking events must be registered attendees who purchased tickets.", 
  },
  {
    id: 11,
    question: "Can I send a substitute in my place?",
    answer: "You may send a substitute in your place at any time. All such requests must be submitted by email to hello@mhworklife.com. Only requests made by the original registrant will be honoured.", 
  },
  {
    id: 12,
    question: "What is the refund policy?",
    answer: "We will not refund tickets at any time. You can transfer your ticket to another participant.", 
  },

];