import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Masonry from "react-masonry-css";

import SummitAgendaCard from "./SummitAgendaCard";
import { CORE_PROGRAMMING, SUMMIT_AGENDA } from "../../utils/summit-data";

type Props = {};

const SummitAgenda = (props: Props) => {
  const breakpointColumnsObj = {
    default: 2,
    1100: 1,
    700: 1,
    500: 1,
  };
  return (
    <Fragment>
      <section id="agenda" className="pt-6">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "38px", sm: "44px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={"-0.04em"}
          className="mx-auto text-center mt-10"
        >
          {/* The Agenda */}
          Agenda & Core Programming
        </Typography>

        <Box className="bg-gray-300 h-[1px] w-[91.2%] my-7 mx-auto place-content-center"></Box>

        <Box className="md:px-0 px-0 mb-8 lg:px-20 py-4 place-content-center items-center">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {SUMMIT_AGENDA.map((agenda, index) => (
              <SummitAgendaCard
                time={agenda.time}
                title={agenda.title}
                info={agenda.info}
                icon={agenda.icon}
              />
            ))}

            {/* {CORE_PROGRAMMING.map((agenda, index) => (
              <SummitAgendaCard
                title={agenda.title}
                info={agenda.info}
                // time={agenda.time}
                key={index}
              />
            ))} */}
          </Masonry>
        </Box>
      </section>
    </Fragment>
  );
};

export default SummitAgenda;
