import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type CardProps = {
  time?: string;
  title?: string;
  info?: string;
  icon?: string | any;
};

const SummitAgendaCard = (props: CardProps) => {
  return (
    <div className="bg-cream-100 rounded-md mx-auto place-content-center h-auto w-[335px] px-4 pt-1 pb-4 md:pt-[10px] md:px-[20px] md:pb-[16px] md:w-[562px]">
      <Box className="w-[307px] md:w-[494px]">
        <Typography
          variant="subtitle1"
          color="primary"
          fontSize={{ xs: "11px", sm: "11px" }}
          lineHeight={{ xs: "11px%", sm: "11px%" }}
          letterSpacing={"0.115em"}
          className="mx-auto text-left mt-10"
        >
          {props.time}
        </Typography>
        <Box className="bg-gray-300 h-[1px] w-[100%] my-3 mx-auto"></Box>
        <Typography
          variant="body2"
          color="primary"
          fontSize={{ xs: "15px", sm: "18px" }}
          lineHeight={{ xs: "200%", sm: "169%" }}
          letterSpacing={"0.02em"}
          className="text-left mx-auto mr-2"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          color="primary"
          fontSize={{ xs: "13px", sm: "14px" }}
          lineHeight={{ xs: "185%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="text-left py-3 mx-auto"
        >
          {props.info}
        </Typography>

        {props.icon && (
          <Box className="flex place-content-left ">
            <Typography
              variant="subtitle1"
              color="primary"
              fontSize={{ xs: "11px", sm: "11px" }}
              lineHeight={{ xs: "11px%", sm: "11px%" }}
              letterSpacing={"0.115em"}
              className="mr-2 text-left mt-3 uppercase"
            >
              presented by
            </Typography>

            <img
              src={props.icon}
              alt="Image"
              style={{}}
              className="h-10 w-[160px] grayscale"
              // className="w-full object-cover grayscale"
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SummitAgendaCard;
