import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MHDialog from '../Common/Dialog/MHDialog';
import MHFormControl from '../Common/Form/MHFormControl';
import MHButton from '../Common/Button/MHButton';
import useInput from '../../hooks/use-input';

import * as validators from '../../utils/validators';
import * as constants from '../../utils/constants';
import { MHSelect } from '../Common/Form/MHSelect';

///firebase
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { resolveErrorMessage } from '../../utils/utils';
import useForm from '../../hooks/use-form';
import useDialog from '../../hooks/use-dialog';
import MHFormGroup from '../Common/Form/MHFormGroup';
import RequestConfirmationPrompt from '../Contact/RequestConfirmation';
import { Brand_Description } from '../../utils/summit-data';

const InvestorsDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    value: enteredName,
    valid: enteredNameIsValid,
    error: enteredNameHasError,
    onChange: nameInputChangeHandler,
    onBlur: nameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);
  const {
    value: enteredBestDescription,
    valid: enteredBestDescriptionIsValid,
    error: enteredBestDescriptionHasError,
    onChange: bestDescriptionInputChangeHandler,
    onBlur: bestDescriptionInputBlurHandler
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: enteredCompanyNameHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredPosition,
    valid: enteredPositionIsValid,
    error: enteredPositionHasError,
    onChange: positionInputChangeHandler,
    onBlur: positionInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredBudget,
    valid: enteredBudgetIsValid,
    error: enteredBudgetHasError,
    onChange: budgetInputChangeHandler,
    onBlur: budgetInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const [completed, setCompleted] = React.useState(false);

  let formIsValid =
    enteredNameIsValid &&
    enteredWorkEmailIsValid &&
    enteredCompanyNameIsValid &&
    enteredPositionIsValid &&
    enteredBestDescriptionIsValid &&
    enteredBudgetIsValid;

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'REQUEST':
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null
        };
        return updatedRequestState;

      case 'RESPONSE':
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null
  });

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateForm();

    if (!formIsValid) {
      return;
    }

    dispatch({ type: 'REQUEST' });

    await addDoc(collection(db, 'brands-inquiry-requests'), {
      Name: enteredName,
      WorkEmail: enteredWorkEmail,
      CompanyName: enteredCompanyName,
      Position: enteredPosition,
      Description: enteredBestDescription,
      Budget: enteredBudget,
      createdAt: serverTimestamp()
    });

    handleOpenDialog();
    onClose();
    resetForm();

    dispatch({ type: 'RESPONSE', error: null, data: 'success' });
  };

  const { validateForm } = useForm();

  const resetForm = () => {
    nameInputChangeHandler('');
    workEmailInputChangeHandler('');
    companyNameInputChangeHandler('');
    budgetInputChangeHandler('');
    bestDescriptionInputChangeHandler('');
    positionInputChangeHandler('');
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={!completed ? ' ' : ' '}
        handleClose={onClose}
        scroll="paper"
        actions={null}
        maxWidth={openDialog ? 'xs' : 'sm'}
        fullWidth>
        <MHFormGroup
          onSubmit={submitHandler}
          disableWhileSubmitting={httpState.loading}>
          <Typography
            variant="h2"
            fontSize={{ xs: '24px', sm: '28px', md: '30px' }}
            lineHeight={{ xs: '142%', sm: '143.5%' }}
            letterSpacing={'-0.04em'}
            className="capitalize mx-auto text-center"
            justifyContent="center"
            alignItems="center"
            textAlign={'center'}
            paddingBottom={4}>
            Partner With Us
          </Typography>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="name"
                type="text"
                label="Full Name"
                placeholder="Name of the person making the request"
                value={enteredName}
                onChange={nameInputChangeHandler}
                onBlur={nameInputBlurHandler}
                error={resolveErrorMessage(enteredNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
                required
                // error={merchantNameErrorTip}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MHFormControl
                id="email"
                type="text"
                label=" Email"
                placeholder="Enter your email address"
                value={enteredWorkEmail}
                onChange={workEmailInputChangeHandler}
                onBlur={workEmailInputBlurHandler}
                required
                error={resolveErrorMessage(enteredWorkEmailHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="companyName"
                type="text"
                label="Organization / Company"
                placeholder="Who do you work for?"
                value={enteredCompanyName}
                onChange={companyNameInputChangeHandler}
                onBlur={companyNameInputBlurHandler}
                required
                error={resolveErrorMessage(enteredCompanyNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="position"
                type="text"
                label="Job Titles"
                placeholder="What is your role?"
                value={enteredPosition}
                onChange={positionInputChangeHandler}
                onBlur={positionInputBlurHandler}
                required
                error={resolveErrorMessage(enteredPositionHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="budget"
                type="text"
                label="Do you have a marketing budget"
                placeholder="What is your marketing budget?"
                value={enteredBudget}
                onChange={budgetInputChangeHandler}
                onBlur={budgetInputBlurHandler}
                required
                error={resolveErrorMessage(enteredBudgetHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHSelect
                label="Which of the following best describes you?"
                placeholder="How can we best describes you? "
                options={Brand_Description}
                value={enteredBestDescription}
                onChange={(val) =>
                  bestDescriptionInputChangeHandler(val as string)
                }
                onBlur={bestDescriptionInputBlurHandler}
                error={resolveErrorMessage(enteredBestDescriptionHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <MHButton
            fullWidth
            sx={{ paddingY: 2, marginY: 2 }}
            type="submit"
            // onClick={submitHandler}
            // onClick={() => handleOpenDialog()}
            loading={httpState.loading}
            className=" text-[12px] leading-[102%] w-full tracking-[0.05em] h-[52px] ">
            Submit
          </MHButton>
        </MHFormGroup>
      </MHDialog>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={handleCloseDialog}
          header="We'll be in touch soon!"
          message="Thank you for your interest in MH WorkLife. We will be in touch with
          you shortly."
        />
      )}
    </React.Fragment>
  );
};

export default InvestorsDialog;
