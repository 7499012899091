import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MHDialog from '../Common/Dialog/MHDialog';
import MHFormControl from '../Common/Form/MHFormControl';
import MHButton from '../Common/Button/MHButton';
import useInput from '../../hooks/use-input';

import * as validators from '../../utils/validators';
import * as constants from '../../utils/constants';
import { MHSelect } from '../Common/Form/MHSelect';
import { CONTACT_MODE } from '../../utils/landing-data';
import MHPhoneInput from '../Common/PhoneInput/MHPhoneInput';
import usePhoneInput from '../../hooks/use-phone';

///firebase
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { resolveErrorMessage } from '../../utils/utils';
import useForm from '../../hooks/use-form';
import { MuiTelInputInfo } from 'mui-tel-input';
import useDialog from '../../hooks/use-dialog';
import MHFormGroup from '../Common/Form/MHFormGroup';
import RequestConfirmationPrompt from '../Contact/RequestConfirmation';

const ParentsDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: enteredFirstNameHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);
  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    error: enteredLastNameHasError,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);
  const {
    value: enteredJobTitle,
    valid: enteredJobTitleIsValid,
    error: enteredJobTitleHasError,
    onChange: jobTitleInputChangeHandler,
    onBlur: jobTitleInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredResponsibilities,
    valid: enteredResponsibilitiesIsValid,
    error: enteredResponsibilitiesHasError,
    onChange: responsibilitiesInputChangeHandler,
    onBlur: responsibilitiesInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredChallenges,
    valid: enteredChallengesIsValid,
    error: enteredChallengesHasError,
    onChange: challengesInputChangeHandler,
    onBlur: challengesInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredTopics,
    valid: enteredTopicsIsValid,
    error: enteredTopicsHasError,
    onChange: topicsInputChangeHandler,
    onBlur: topicsInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredAttendance,
    valid: enteredAttendanceIsValid,
    error: enteredAttendanceHasError,
    onChange: attendanceInputChangeHandler,
    onBlur: attendanceInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const [completed, setCompleted] = React.useState(false);

  let formIsValid =
    enteredFirstNameIsValid &&
    enteredLastNameIsValid &&
    enteredJobTitleIsValid &&
    enteredWorkEmailIsValid &&
    enteredResponsibilitiesIsValid &&
    enteredChallengesIsValid &&
    enteredTopicsIsValid &&
    enteredAttendanceIsValid;

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'REQUEST':
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null
        };
        return updatedRequestState;

      case 'RESPONSE':
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null
  });

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateForm();

    if (!formIsValid) {
      return;
    }

    dispatch({ type: 'REQUEST' });

    await addDoc(collection(db, 'parents-inquiry-requests'), {
      Name: enteredFirstName,
      LastName: enteredLastName,
      JobTitle: enteredJobTitle,
      WorkEmail: enteredWorkEmail,
      Responsibilities: enteredResponsibilities,
      Challenges: enteredChallenges,
      Topics: enteredTopics,
      Attendance: enteredAttendance,
      createdAt: serverTimestamp()
    });

    handleOpenDialog();
    onClose();
    resetForm();

    dispatch({ type: 'RESPONSE', error: null, data: 'success' });
  };

  const { validateForm } = useForm();

  const resetForm = () => {
    firstNameInputChangeHandler('');
    lastNameInputChangeHandler('');
    jobTitleInputChangeHandler('');
    workEmailInputChangeHandler('');
    responsibilitiesInputChangeHandler('');
    attendanceInputChangeHandler('');
    topicsInputChangeHandler('');
    challengesInputChangeHandler('');
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  // let history = useHistory();

  // const handleClickOpen = () => {
  //   // history.push(`/`);
  //   // onClose();
  //   setCompleted(true);
  // };
  // const closure = () => {
  //   // history.push(`/`);
  //   resetForm();
  //   onClose();
  //   setCompleted(false);
  // };

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={!completed ? ' ' : ' '}
        handleClose={onClose}
        scroll="paper"
        actions={null}
        maxWidth={completed ? 'xs' : 'sm'}
        fullWidth>
        <MHFormGroup
          onSubmit={submitHandler}
          disableWhileSubmitting={httpState.loading}>
          <Typography
            variant="h2"
            fontSize={{ xs: '24px', sm: '28px', md: '30px' }}
            lineHeight={{ xs: '142%', sm: '143.5%' }}
            letterSpacing={'-0.04em'}
            className="capitalize mx-auto text-center"
            justifyContent="center"
            alignItems="center"
            textAlign={'center'}
            paddingBottom={4}>
            Working Parents & Caregivers Inquiry
          </Typography>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="firstname"
                type="text"
                label=" First Name"
                placeholder="What is your first name?"
                value={enteredFirstName}
                onChange={firstNameInputChangeHandler}
                onBlur={firstNameInputBlurHandler}
                error={resolveErrorMessage(enteredFirstNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
                required
                // error={merchantNameErrorTip}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MHFormControl
                id="lastname"
                type="text"
                label=" Last Name"
                placeholder="What is your last name?"
                value={enteredLastName}
                onChange={lastNameInputChangeHandler}
                onBlur={lastNameInputBlurHandler}
                error={resolveErrorMessage(enteredLastNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
                required
                // error={merchantNameErrorTip}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="jobTitle"
                type="text"
                label=" Job Title"
                placeholder="What is your job title?"
                value={enteredJobTitle}
                onChange={jobTitleInputChangeHandler}
                onBlur={jobTitleInputBlurHandler}
                error={resolveErrorMessage(enteredJobTitleHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
                required
                // error={merchantNameErrorTip}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MHFormControl
                id="email"
                type="text"
                label=" Email"
                placeholder="Enter your email address"
                value={enteredWorkEmail}
                onChange={workEmailInputChangeHandler}
                onBlur={workEmailInputBlurHandler}
                required
                error={resolveErrorMessage(enteredWorkEmailHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={12}>
              <MHFormControl
                id="responsibilities"
                type="text"
                label="Please briefly describe your specific caregiving needs or responsibilities."
                placeholder=" (To better understand the diverse caregiving situations and customize support)"
                value={enteredResponsibilities}
                onChange={responsibilitiesInputChangeHandler}
                onBlur={responsibilitiesInputBlurHandler}
                required
                error={resolveErrorMessage(enteredResponsibilitiesHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MHFormControl
                id="challenges"
                type="text"
                label="What are the specific challenges you face in balancing work and caregiving..."
                placeholder="What are the specific challenges you face in balancing work and caregiving responsibilities?"
                value={enteredChallenges}
                onChange={challengesInputChangeHandler}
                onBlur={challengesInputBlurHandler}
                required
                error={resolveErrorMessage(enteredChallengesHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={12}>
              <MHFormControl
                id="topics"
                type="text"
                label="What topics or areas would you like to see covered during the summit sessions?                "
                placeholder="What topics or areas would you like to see covered during the summit sessions?                "
                value={enteredTopics}
                onChange={topicsInputChangeHandler}
                onBlur={topicsInputBlurHandler}
                required
                error={resolveErrorMessage(enteredTopicsHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MHFormControl
                id="attendance"
                type="text"
                label="Have you attended similar events or conferences in the past?"
                placeholder=" If yes, please share your experience and key takeaways.                "
                value={enteredAttendance}
                onChange={attendanceInputChangeHandler}
                onBlur={attendanceInputBlurHandler}
                required
                error={resolveErrorMessage(enteredAttendanceHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <MHButton
            fullWidth
            sx={{ paddingY: 2, marginY: 2 }}
            type="submit"
            // onClick={submitHandler}
            // onClick={() => handleOpenDialog()}
            loading={httpState.loading}
            className=" text-[12px] leading-[102%] w-full tracking-[0.05em] h-[52px] ">
            Submit
          </MHButton>
        </MHFormGroup>
      </MHDialog>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={handleCloseDialog}
          header="We'll be in touch soon!"
          message="Thank you for your interest in MH WorkLife. We will be in touch with
          you shortly."
        />
      )}
    </React.Fragment>
  );
};

export default ParentsDialog;
